import React from "react";
import PaymentDetailsController, {
  Props,
  configJSON,
} from "./PaymentDetailsController";
import { Box } from "@material-ui/core";
import { verifyEmailBackground, logo, stripeIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

export default class PaymentDetails extends PaymentDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box height={"auto"}>
        <Box style={webStyle.container}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"inherit"}
            width={"100%"}
            margin={"auto"}
          >
            <Box width={"551px"}>
              <Box sx={webStyle.paymentDetailsContainer}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  paddingBottom={"32px"}
                >
                  <img src={logo} alt="logo" width={187} height={81} />
                </Box>
                <Box
                  data-test-id="payment-details-title"
                  textAlign={"center"}
                  style={webStyle.title}
                >
                  {configJSON.paymentDetailsLabel}
                </Box>
                <Box
                  textAlign={"center"}
                  style={webStyle.paymentDetailsDescription}
                >
                  {configJSON.welcomePaymentDetailsLabel}
                </Box>
                <Box
                  borderTop={"1px solid #44403C"}
                  borderBottom={"1px solid #44403C"}
                  padding={"16px"}
                  margin={"32px 0px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Box style={webStyle.subscriptionLabel}>
                        {configJSON.subscriptionLabel}
                      </Box>
                      <Box style={webStyle.premiumLabel}>
                        {configJSON.premiumLabel}
                      </Box>
                    </Box>
                    <Box>
                      <Box display={"flex"} alignItems={"baseline"}>
                        <Box style={webStyle.priceLabel}>
                          {configJSON.priceAmountLabel}
                        </Box>
                        <Box
                          paddingLeft={"8px"}
                          style={webStyle.subscriptionLabel}
                        >
                          {configJSON.monthlyLabel}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <CustomButton
                    label={configJSON.payWithStripeLabel}
                    onClick={this.payWithStripe}
                    variant="contained"
                    width={265}
                    icon={stripeIcon}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  container: {
    backgroundImage: `url(${verifyEmailBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001327",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
  },
  paymentDetailsContainer: {
    padding: "32px 40px",
    borderRadius: "16px",
    backdropFilter: "blur(8px)",
    background: "rgba(0, 19, 39, 0.60)",
  },
  title: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  paymentDetailsDescription: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.5,
    paddingTop: "8px",
  },
  subscriptionLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.57,
  },
  premiumLabel: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "20px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.4,
  },
  priceLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: 1.33,
    letterSpacing: -0.12,
  },
};
