import React from "react";
import { Box, TextField, makeStyles } from "@material-ui/core";
import CustomButton from "./CustomButton.web";
import CustomDatePicker from "./CustomDatePicker.web";
import { Moment } from "moment";

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#002348",
      borderRadius: "8px",
      width: "240px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "120px",
      },
      "& fieldset": {
        borderColor: "#B4B4B4 !important",
      },
      "& input::placeholder": {
        color: "#FFF",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "center",
      },
      "& input": {
        color: "#FFF",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "center",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px",
    },
    "& .MuiOutlinedInput-root.Mui-error": {
      "& fieldset": {
        borderColor: "#B4B4B4 !important",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontFamily: "Open Sans",
    },
  },
  dateBox: {
    border: "1px solid #B4B4B4",
    padding: "12px 0px",
    backgroundColor: "#002348",
    color: "#B4B4B4",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    borderRadius: "8px",
    width: "240px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
  },
}));

interface ISummarySetupProps {
  identifierList: { name: string; value: string }[];
  handleAmountChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  selectedDate: Moment | null;
  handleDateChange: (date: Moment | null) => void;
  onContinue: () => void;
}

const CustomSummarySetup: React.FC<ISummarySetupProps> = ({
  identifierList,
  handleAmountChange,
  selectedDate,
  handleDateChange,
  onContinue,
}) => {
  const classes = useStyles();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"inherit"}
      width={"100%"}
      margin={"auto"}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "482px",
            md: "582px",
            lg: "682px",
          },
        }}
      >
        <Box sx={webStyle.summaryContainer}>
          <Box textAlign={"center"} style={webStyle.setUpSummariesTitle}>
            Set up your Summaries
          </Box>
          <Box
            textAlign={"center"}
            margin={"8px 0px"}
            style={webStyle.setUpSummariesSubtitle}
          >
            Fill in the information below to view a Summary for the current
            month.
          </Box>
          <Box margin={"24px 0px"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box style={webStyle.summarySetupLabel}>Month end:</Box>
              <CustomDatePicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
              />
            </Box>
          </Box>
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box style={webStyle.summarySetupLabel}>
                Prepaid Account Identifier:
              </Box>
              <Box width={"240px"}>
                <Box style={webStyle.summarySetupLabel} textAlign={"center"}>
                  Starting GL Amount:
                </Box>
              </Box>
            </Box>
            {identifierList.map((item, index) => (
              <Box margin={"8px 0px 0px"} key={item.name}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box style={webStyle.summarySetupLabel}>{item.name}</Box>
                  <TextField
                    className={classes.textfield}
                    variant="outlined"
                    value={item.value ? `$${item.value}` : ""}
                    placeholder="Enter amount"
                    onChange={(e) => handleAmountChange(e, index)}
                  />
                </Box>
              </Box>
            ))}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={"24px"}
            >
              <CustomButton
                variant="contained"
                onClick={onContinue}
                width={197}
                disabled={
                  identifierList.some((object) => object.value === "") ||
                  !selectedDate
                }
                label="Continue"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const webStyle = {
  summaryContainer: {
    borderRadius: "16px",
    padding: "32px 56px",
    background: "rgba(0, 19, 39, 0.60)",
    backdropFilter: "blur(8px)",
    "@media (max-width: 960px)": {
      padding: "32px 16px",
    },
    "@media (max-width: 600px)": {
      padding: "32px 8px",
    },
  },
  setUpSummariesTitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
  },
  setUpSummariesSubtitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },
  summarySetupLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    padding: "0px 16px",
  },
};

export default CustomSummarySetup;
