import React from "react";
import NewPasswordController, {
  Props,
  configJSON,
} from "./NewPasswordController";
import { Box } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput.web";
import { backgroundImg, checkIcon, logo, passIcon, failIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const showValidationCheckIcon = (isCheck: boolean | undefined) => {
      let iconImg = checkIcon;
      if (typeof isCheck === "boolean") {
        iconImg = isCheck ? passIcon : failIcon;
      }
      return iconImg;
    };
    const {
      confirmPassword,
      password,
      validationChecks,
      recoveryEmail,
      validationFailedError,
      passwordChangedMsg,
      passwordError,
      confirmPasswordError,
    } = this.state;

    const isResetPasswordDisabled = () => {
      const isDisabled =
        !!passwordError ||
        !!confirmPasswordError ||
        !confirmPassword ||
        !password;

      return isDisabled;
    };
    return (
      <Box height={"auto"}>
        <Box style={webStyle.parentContainer}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"inherit"}
            width={"100%"}
            margin={"auto"}
          >
            <Box width={"551px"}>
              <Box sx={webStyle.newPasswordContainer}>
                <Box
                  display={"flex"}
                  style={webStyle.pointerStyle}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => this.handleNavigation("Home")}
                >
                  <img src={logo} alt="logo" width={200} height={128} />
                </Box>
                <Box textAlign={"center"} style={webStyle.newPasswordTitle}>
                  {configJSON.newPassword}
                </Box>
                <Box
                  textAlign={"center"}
                  style={webStyle.newPasswordDescription}
                >
                  {configJSON.resendPasswordDescription + recoveryEmail}
                </Box>
                {validationFailedError && (
                  <Box
                    style={webStyle.validationFailedError}
                    data-test-id="error-message"
                  >
                    {validationFailedError}
                  </Box>
                )}
                {passwordChangedMsg && (
                  <Box
                    style={webStyle.passwordChangedMsg}
                    data-test-id="success-message"
                  >
                    {passwordChangedMsg}
                  </Box>
                )}
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.inputLabel}>
                    {configJSON.newPassword}
                  </Box>
                  <CustomInput
                    value={password}
                    handleChange={(event) =>
                      this.handleChangeNewPasswordField(event, "password")
                    }
                    placeholder={configJSON.newPassword}
                    errorMessage={passwordError}
                    isError={!!passwordError}
                  />
                </Box>
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.inputLabel}>
                    {configJSON.confirmNewPassword}
                  </Box>
                  <CustomInput
                    value={confirmPassword}
                    handleChange={(event) =>
                      this.handleChangeNewPasswordField(
                        event,
                        "confirmPassword"
                      )
                    }
                    placeholder={configJSON.confirmNewPassword}
                    errorMessage={confirmPasswordError}
                    isError={!!confirmPasswordError}
                  />
                </Box>
                <Box margin={"16px 0px"}>
                  <Box style={webStyle.passwordContainText}>
                    {configJSON.passwordTypeDetails}
                  </Box>
                  {validationChecks.map((item) => (
                    <Box
                      key={item.name}
                      display={"flex"}
                      alignItems={"center"}
                      paddingBottom={"8px"}
                    >
                      <Box paddingRight={"8px"}>
                        <img
                          src={showValidationCheckIcon(item.isPassed)}
                          height={18}
                          alt="check"
                        />
                      </Box>
                      <Box style={webStyle.alreadyHaveAnAccountText}>
                        {item.name}
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box margin={"24px 0px"}>
                  <CustomButton
                    fullWidth
                    variant="contained"
                    onClick={this.resetPassword}
                    label={configJSON.resetPasswordBtn}
                    disabled={isResetPasswordDisabled()}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box style={webStyle.alreadyHaveAnAccountText}>
                    {configJSON.alreadyHaveAccountText}
                  </Box>
                  <Box
                    data-test-id="login-btn"
                    style={webStyle.logInText}
                    onClick={this.handleLoginRedirection}
                  >
                    {configJSON.loginLabel}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  parentContainer: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "#001327",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
  },
  newPasswordContainer: {
    padding: "32px 95px",
    borderRadius: "16px",
    backdropFilter: "blur(8px)",
    background: "rgba(0, 19, 39, 0.60)",
    "@media (max-width: 600px)": {
      padding: "32px 46px",
    },
  },
  newPasswordTitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "36px",
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  newPasswordDescription: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    paddingTop: "4px",
    paddingBottom: "16px",
  },
  inputContainer: {
    padding: "8px 0px",
  },
  inputLabel: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    paddingBottom: "4px",
    lineHeight: 1.5,
  },
  alreadyHaveAnAccountText: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.57,
  },
  logInText: {
    background: "#FFF",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    cursor: "pointer",
    borderBottom: "1px solid #FFF",
    marginLeft: "24px",
  },
  pointerStyle: {
    cursor: "pointer",
  },
  passwordContainText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#FFF",
    paddingBottom: "8px",
  },
  validationFailedError: {
    fontSize: "12px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#DC2626",
    lineHeight: 1.5,
    backgroundColor: "#FEE2E2",
    borderLeft: "2px solid #DC2626",
    marginBottom: "26px",
    borderRadius: "4px",
    padding: "21px 16px",
  },
  passwordChangedMsg: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#01AA7F",
    backgroundColor: "#FFF",
    borderLeft: "2px solid ##01AA7F",
    borderRadius: "4px",
    marginBottom: "26px",
    padding: "21px 16px",
  },
};
