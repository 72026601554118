import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { getSidebarToggle } from "../../../components/src/Utilities";
import {
  contact,
  details,
  journal,
  journalDisabled,
  logout,
  settings,
  summary,
  summaryDisabled,
} from "./assets";

export const configJSON = require("./config");

interface ISidebarList {
  tab: string;
  icon: string;
  path: string;
  disabledIcon?: string;
  isDisabled?: boolean;
}

const sidebarList: ISidebarList[] = [
  {
    tab: "Summary",
    icon: summary,
    path: "Summary",
    disabledIcon: summaryDisabled,
    isDisabled: true,
  },
  {
    tab: "Details",
    icon: details,
    path: "Details",
  },
  {
    tab: "Journal Entries",
    icon: journal,
    path: "JournalEntries",
    disabledIcon: journalDisabled,
    isDisabled: true,
  },
  {
    tab: "Settings",
    icon: settings,
    path: "Settings",
  },
  {
    tab: "Contact us",
    icon: contact,
    path: "ContactUs",
  },
  { tab: "Log out", icon: logout, path: "Logout" },
];

export interface Props {
  navigation: any;
  onResize: (isSidebarOpen: boolean) => void;
  enableAllTabs?: boolean;
}

interface S {
  toggleSidebar: boolean;
  isMobileView: boolean;
  toggleMobileView: boolean;
  isTabView: boolean;
  isOpenLogoutDialog: boolean;
  sidebarList: ISidebarList[];
}

interface SS {}

export default class NavigationSidebarController extends BlockComponent<
  Props,
  S,
  SS
> {
  logoutApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      toggleSidebar: getSidebarToggle(),
      isMobileView: window.innerWidth < 600,
      toggleMobileView: false,
      isTabView: window.innerWidth < 960 && window.innerWidth > 600,
      isOpenLogoutDialog: false,
      sidebarList: sidebarList,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    const firstLoad = await getStorageData("isFirstLoad");
    if (!token) {
      this.handleSidebarNavigation("Home");
      return;
    }
    if (firstLoad !== "true") {
      this.setState({
        sidebarList: sidebarList.map((object) => ({
          ...object,
          isDisabled: false,
        })),
      });
    }
    window.addEventListener("resize", this.handleResize);
    this.props.onResize(getSidebarToggle());
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevProps.enableAllTabs !== this.props.enableAllTabs) {
      if (this.props.enableAllTabs) {
        this.setState({
          sidebarList: sidebarList.map((item) => ({
            ...item,
            isDisabled: false,
          })),
        });
        await setStorageData("isFirstLoad", "false");
      } else {
        this.setState({ sidebarList: sidebarList });
        await setStorageData("isFirstLoad", "true");
      }
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.logoutApiId) {
      this.onCloseLogoutDialog();
      this.navigateToPage("Home");
    }
  }

  handleResize = async () => {
    this.setState(
      {
        isMobileView: window.innerWidth < 600,
        toggleSidebar:
          !this.state.isTabView &&
          !(window.innerWidth < 960 && window.innerWidth > 600),
        isTabView: window.innerWidth < 960 && window.innerWidth > 600,
      },
      () => {
        this.props.onResize(this.state.toggleSidebar);
      }
    );
    await setStorageData(
      "toggleSidebar",
      JSON.stringify(
        !this.state.isTabView &&
          !(window.innerWidth < 960 && window.innerWidth > 600)
      )
    );
  };

  setToggleSidebar = async (toggle: boolean) => {
    this.setState({ toggleSidebar: !toggle }, () => {
      this.props.onResize(this.state.toggleSidebar);
    });
    await setStorageData("toggleSidebar", JSON.stringify(!toggle));
  };

  setToggleMobileView = (toggle: boolean) => {
    this.setState({ toggleMobileView: !toggle });
  };

  handleSidebarNavigation = (pathString: string) => {
    if (pathString !== "Logout") {
      this.navigateToPage(pathString);
    } else {
      this.setState({ isOpenLogoutDialog: true });
    }
  };

  navigateToPage = async (path: string) => {
    const isFirstLoad = await getStorageData("isFirstLoad");

    if (
      (path === "Summary" || path === "JournalEntries") &&
      isFirstLoad === "true"
    ) {
      return;
    }
    const routeTargetMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeTargetMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    routeTargetMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(routeTargetMsg);
  };

  onCloseLogoutDialog = () => {
    this.setState({ isOpenLogoutDialog: false });
  };

  onLogout = async () => {
    const token = await getStorageData("token");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const logoutRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutApiId = logoutRequestMsg.messageId;

    logoutRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutApiEndPoint
    );

    logoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    logoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ token: token })
    );
    logoutRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(logoutRequestMsg.id, logoutRequestMsg);
  };
}
