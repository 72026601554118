import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment";
import {
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
const dateIcon = require("./date-icon.svg");
const dateIconWhite = require("./date-icon-white.svg");

const theme = createTheme({
  palette: {
    primary: {
      main: "#01AA7F",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#002348",
      borderRadius: "8px",
      width: "240px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "120px",
      },
      "& fieldset": {
        borderColor: "#B4B4B4 !important",
      },
      "& input::placeholder": {
        color: "#FFF",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "center",
      },
      "& input": {
        color: "#FFF",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        paddingLeft: (props: IDatePickerProps) =>
          props.selectedDate ? "100px" : "44px",
        [theme.breakpoints.down("md")]: {
          paddingLeft: (props: IDatePickerProps) =>
            props.selectedDate ? "74px" : "46px",
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "38px",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 0px",
    },
    "& .MuiOutlinedInput-root.Mui-error": {
      "& fieldset": {
        borderColor: "#B4B4B4 !important",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontFamily: "Open Sans",
    },
  },
  iconButton: {
    position: "absolute",
    color: "#FFF",
    left: (props: IDatePickerProps) => (props.selectedDate ? 72 : 64),
    top: 12.5,
    [theme.breakpoints.down("md")]: {
      left: (props: IDatePickerProps) => (props.selectedDate ? 46 : 46),
    },
    [theme.breakpoints.down("sm")]: {
      left: 14,
    },
  },
  popup: {
    "& .MuiPickersCalendarHeader-iconButton": {
      color: "#01AA7F !important",
    },
  },
}));

interface IDatePickerProps {
  selectedDate: Moment | null;
  handleDateChange: (date: Moment | null) => void;
}

const CustomDatePicker: React.FC<IDatePickerProps> = ({
  selectedDate,
  handleDateChange,
}: IDatePickerProps) => {
  const classes = useStyles({ selectedDate, handleDateChange });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YY"
          margin="normal"
          label=""
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
            className: classes.iconButton,
            size: "small",
          }}
          keyboardIcon={<img src={selectedDate ? dateIconWhite : dateIcon} alt="date" height={16} />}
          TextFieldComponent={(props) => (
            <TextField
              inputProps={{
                readOnly: true,
              }}
              {...props}
              className={classes.textfield}
              variant="outlined"
              placeholder="Select Date"
            />
          )}
          autoOk
          PopoverProps={{
            classes: {
              paper: classes.popup,
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default CustomDatePicker;
