import React from "react";
import { Box, Grid } from "@material-ui/core";
import { stripeIcon } from "./assets";
import NavigationSidebar from "../../../blocks/navigationmenu/src/NavigationSidebar.web";
import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController.web";
import CustomTabStepper from "../../../components/src/CustomTabStepper.web";
import CustomButton from "../../../components/src/CustomButton.web";
import SettingsDeleteAccount from "./SettingsDeleteAccount.web";
import SettingsTermsConditions from "./SettingsTermsConditions.web";
import SettingsChangePassword from "./SettingsChangePassword.web";
import SettingsEditProfile from "./SettingsEditProfile.web";
import CustomMainPageWrapper from "../../../components/src/CustomMainPageWrapper.web";

export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { activeTab, sidebarWidth } = this.state;
    return (
      <Box
        display={"flex"}
        sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
      >
        <Box>
          <NavigationSidebar
            navigation={this.props.navigation}
            onResize={this.onPageResize}
          />
        </Box>
        <CustomMainPageWrapper width={sidebarWidth}>
          <Box
            sx={{
              padding: {
                xs: "32px 8px",
                sm: "36px 16px",
                md: "40px 24px",
                lg: "48px 32px",
              },
              width: ["-webkit-fill-available", "-moz-available", "fill-available"],
            }}
          >
            <Box style={webStyle.settingsTitle} data-test-id="title-text">
              {configJSON.settingsTitle}
            </Box>
            <Box
              sx={{
                padding: {
                  xs: "24px 0px",
                  sm: "56px 0px",
                },
              }}
            >
              <CustomTabStepper
                stepList={configJSON.settingsTabList}
                activeStep={activeTab}
                onStepClick={(event, step) => this.onTabChange(step)}
              />
            </Box>
            <Box>
              {activeTab === configJSON.settingsTabList[0] && (
                <SettingsEditProfile navigation={this.props.navigation} />
              )}
              {activeTab === configJSON.settingsTabList[1] && (
                <SettingsChangePassword
                  navigation={this.props.navigation}
                  onTabChange={this.onTabChange}
                />
              )}
              {activeTab === configJSON.settingsTabList[2] && (
                <Box>
                  <Box style={webStyle.settingsTabTitle} paddingBottom={"48px"}>
                    {configJSON.settingsTabList[2]}
                  </Box>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={7} lg={6}>
                      <Box
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "flex",
                            lg: "flex",
                          },
                          justifyContent: {
                            xs: "start",
                            sm: "start",
                            md: "space-between",
                            lg: "space-between",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            paddingRight: {
                              lg: "48px",
                              md: "48px",
                              sm: "24px",
                            },
                          }}
                        >
                          <Box
                            style={webStyle.labelText}
                            paddingBottom={"16px"}
                          >
                            {configJSON.subscriptionText}
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <Box style={webStyle.premiumText}>
                              {configJSON.premiumText}
                            </Box>
                            <Box
                              style={webStyle.subscriptionValueText}
                              paddingLeft={"16px"}
                            >
                              {configJSON.dollar100Monthly}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            paddingRight: {
                              lg: "48px",
                              md: "48px",
                              sm: "24px",
                            },
                          }}
                        >
                          <Box
                            style={webStyle.labelText}
                            paddingBottom={"16px"}
                            sx={{
                              paddingTop: {
                                xs: "16px",
                                sm: "0px",
                              },
                            }}
                          >
                            {configJSON.statusText}
                          </Box>
                          <Box style={webStyle.subscriptionValueText}>
                            {configJSON.activeStatus}
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            style={webStyle.labelText}
                            paddingBottom={"16px"}
                            sx={{
                              paddingTop: {
                                xs: "16px",
                                sm: "0px",
                              },
                            }}
                          >
                            {configJSON.nextBillingDate}
                          </Box>
                          <Box style={webStyle.subscriptionValueText}>
                            {configJSON.billingDate}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={6}>
                      <Box
                        alignItems={"center"}
                        display={"flex"}
                        sx={{
                          justifyContent: {
                            xs: "start",
                            sm: "start",
                            md: "flex-end",
                          },
                          paddingTop: {
                            xs: "10px",
                            sm: "10px",
                          },
                        }}
                      >
                        <CustomButton
                          label={configJSON.editSubscriptionBtn}
                          onClick={() => {}}
                          width={265}
                          variant="contained"
                          icon={stripeIcon}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeTab === configJSON.settingsTabList[3] && (
                <SettingsTermsConditions navigation={this.props.navigation} />
              )}
              {activeTab === configJSON.settingsTabList[4] && (
                <SettingsDeleteAccount
                  navigation={this.props.navigation}
                  handleRedirection={this.handleSettingsNavigation}
                />
              )}
            </Box>
          </Box>
        </CustomMainPageWrapper>
      </Box>
    );
  }
}

const webStyle = {
  settingsTitle: {
    fontFamily: "Brown",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
    fontSize: "40px",
  },
  settingsTabTitle: {
    fontFamily: "Brown",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
    fontSize: "32px",
  },
  labelText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#78716C",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  premiumText: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: -0.12,
  },
  subscriptionValueText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
    fontSize: "16px",
    lineHeight: 1.5,
  },
};
