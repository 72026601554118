import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  activeTab: string;
  sidebarWidth: number;
}

interface SS {}

export default class SettingsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [];

    this.state = {
      activeTab: configJSON.settingsTabList[0],
      sidebarWidth: 100,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  onTabChange = (tabName: string) => {
    this.setState({ activeTab: tabName });
  };

  onPageResize = (isSidebarOpen: boolean) => {
    this.setState({ sidebarWidth: isSidebarOpen ? 241 : 100 });
  };

  handleSettingsNavigation = (pathString: string) => {
    const routeTargetMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeTargetMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pathString
    );
    routeTargetMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(routeTargetMsg);
  };
}
