import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Box,
  TextField,
} from "@material-ui/core";

import CustomSearchableDropdown from "./CustomSearchableDropdown.web";
import { reducedFileName } from "./Utilities";
const pinIcon = require("../src/pin.svg");

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "auto",
    margin: "auto",
    paddingBottom: "16px",
    width: "max-content",
    maxWidth: "100%",
    "@media screen and (-webkit-min-device-pixel-ratio:0)": {
      maxWidth: "-webkit-fill-available",
    },
    "@supports (--moz-available:0)": {
      maxWidth: "-moz-available",
    },
    "@supports (fill-available:0)": {
      maxWidth: "fill-available",
    },
    "& .table": {
      border: "1px solid #A8A29E",
      borderBottom: "none",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: ICustomJournalEntriesTableProps) =>
        `calc(100vw - ${props.width}px)`,
    },
    "& .table-body-cell": {
      backgroundColor: "#44403C",
      color: "#FFF",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      minWidth: "220px",
      maxWidth: "260px",
      padding: "8px",
    },
    "& .table-head-cell": {
      backgroundColor: "#44403C",
      color: "#FFF",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      padding: "8px 16px",
      minWidth: "220px",
      height: "60px",
      maxWidth: "260px",
    },
  },
  textField: {
    height: "100%",
    "& textarea": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
    },
    "& input": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: "0px !important",
      "& fieldset": {
        border: "none !important",
        borderRadius: "0px !important",
      },
      "&:hover fieldset": {
        border: "none !important",
      },
      "&.Mui-focused fieldset": {
        border: "none !important",
      },
      "&.Mui-disabled fieldset": {
        border: "none !important",
      },
      "&.Mui-error fieldset": {
        border: "none !important",
      },
    },
  },
}));

interface IRawData {
  id?: number;
  images?: { name?: string; url?: string | File | null; id?: number }[];
  vendor_name_id: number;
  vendor_name: string;
  prepaid_account_identifier_id: number;
  prepaid_account_identifier: string;
  invoice_date: string;
  invoice_number: string;
  invoice_description: string;
  check: string;
  amortization_period: string;
  amortization_start: string;
  amortization_end: string;
  expense_account: string;
  exp_account_id: number;
  total_amount: string;
  expense_amount: string;
  remaining_balance: number;
  notes: string;
  account_id: number;
  time_of_creation: string;
}

interface ICustomJournalEntriesTableProps {
  columns: {
    name: string;
    key: keyof IRawData;
    isSelected: boolean;
    attribute: string;
  }[];
  rows: IRawData[];
  width: number;
  prepaidAccountIdentifierList: { name: string; value: string }[];
  vendorList: { name: string; value: string }[];
  expenseAccountList: { name: string; value: string }[];
}

const CustomJournalEntriesTable: React.FC<ICustomJournalEntriesTableProps> = ({
  columns,
  rows,
  width,
  prepaidAccountIdentifierList,
  vendorList,
  expenseAccountList,
}) => {
  const classes = useStyles({
    columns,
    rows,
    width,
    prepaidAccountIdentifierList,
    vendorList,
    expenseAccountList,
  });

  return (
    <Box className={classes.tableContainer}>
      <Table stickyHeader className="table">
        <TableHead>
          <TableRow>
            {columns
              .filter((item) => item.isSelected)
              .map((column) => (
                <TableCell
                  className="table-head-cell"
                  key={column.name}
                  data-test-id="head-cell"
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box width={"max-content"} padding={"0px 4px"}>
                      {column.name}
                    </Box>
                  </Box>
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData: IRawData | any, rowIndex: number) => (
            <TableRow key={`${rowData?.id}-${rowIndex}-row`}>
              {columns
                .filter((obj) => obj.isSelected)
                .map((columnRow, colIndex: number) => (
                  <TableCell
                    align="center"
                    className="table-body-cell"
                    key={columnRow.key + colIndex + "table-cell"}
                  >
                    {columnRow.key === "vendor_name" && (
                      <Box>
                        <CustomSearchableDropdown
                          value={rowData[columnRow.key]}
                          name=""
                          error={false}
                          options={vendorList}
                          handleChange={() => {}}
                          isDark={true}
                          disabled={true}
                          deleteOption={() => {}}
                          updateOption={() => {}}
                        />
                      </Box>
                    )}
                    {columnRow.key === "prepaid_account_identifier" && (
                      <Box>
                        <CustomSearchableDropdown
                          value={rowData[columnRow.key]}
                          name=""
                          error={false}
                          options={prepaidAccountIdentifierList}
                          handleChange={() => {}}
                          isDark={true}
                          disabled={true}
                          deleteOption={() => {}}
                          updateOption={() => {}}
                        />
                      </Box>
                    )}
                    {columnRow.key === "expense_account" && (
                      <Box>
                        <CustomSearchableDropdown
                          value={rowData[columnRow.key]}
                          name=""
                          error={false}
                          options={expenseAccountList}
                          handleChange={() => {}}
                          isDark={true}
                          disabled={true}
                          deleteOption={() => {}}
                          updateOption={() => {}}
                        />
                      </Box>
                    )}
                    {columnRow.key !== "prepaid_account_identifier" &&
                      columnRow.key !== "vendor_name" &&
                      columnRow.key !== "expense_account" &&
                      columnRow.key !== "images" && (
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          value={
                            rowData[columnRow.key] !== undefined &&
                            rowData[columnRow.key] !== null &&
                            rowData[columnRow.key] !== "null"
                              ? `${
                                  columnRow.key === "total_amount" ||
                                  columnRow.key === "expense_amount" ||
                                  columnRow.key === "remaining_balance"
                                    ? "$" + rowData[columnRow.key]
                                    : rowData[columnRow.key]
                                }`
                              : ""
                          }
                          multiline
                          fullWidth
                          disabled
                        />
                      )}
                    {columnRow.key === "images" &&
                      rowData?.images?.map((image: any) => (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Box marginRight={"12px"}>
                            <img src={pinIcon} height={14} alt="pin" />
                          </Box>
                          <Box>{reducedFileName(image?.name)}</Box>
                        </Box>
                      ))}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CustomJournalEntriesTable;
