import React from "react";
import LandingPageController, { Props } from "./LandingPageController";
import { Box } from "@material-ui/core";

import LandingPageContainer from "../../../components/src/LandingPageContainer.web";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box height={"auto"}>
        <LandingPageContainer
          onLogin={this.redirectToLogin}
          onSubscribe={this.redirectToSignUp}
          videoUrl={this.state.videoLink}
        />
      </Box>
    );
  }
}
