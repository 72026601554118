import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment";
import {
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
const dateIcon = require("./date-icon.svg");
const dateIconWhite = require("./date-icon-white.svg");

const theme = createTheme({
  palette: {
    primary: {
      main: "#01AA7F",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  textfield: {
    margin: "0px",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#002348",
      borderRadius: "8px",
      width: "161px",
      [theme.breakpoints.down("md")]: {
        width: "161px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "161px",
      },
      "& fieldset": {
        borderColor: "#B4B4B4 !important",
        borderWidth: "2px"
      },
      "& input::placeholder": {
        color: "#FFF",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "center",
      },
      "& input": {
        color: "#FFF",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        paddingLeft: (props: IDateRangePickerProps) =>
          props.selectedDate ? "52px" : "44px",
        [theme.breakpoints.down("md")]: {
          paddingLeft: (props: IDateRangePickerProps) =>
            props.selectedDate ? "52px" : "46px",
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: "52px",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 0px",
    },
    "& .MuiOutlinedInput-root.Mui-error": {
      "& fieldset": {
        borderColor: "#B4B4B4 !important",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontFamily: "Open Sans",
    },
  },
  iconButton: {
    position: "absolute",
    color: "#FFF",
    left: (props: IDateRangePickerProps) => (props.selectedDate ? 26 : 26),
    top: 11.5,
    [theme.breakpoints.down("md")]: {
      left: (props: IDateRangePickerProps) => (props.selectedDate ? 26 : 26),
    },
    [theme.breakpoints.down("sm")]: {
      left: 26,
    },
  },
  popup: {
    "& .MuiPickersCalendarHeader-iconButton": {
      color: "#01AA7F !important",
    },
  },
}));

interface IDateRangePickerProps {
  selectedDate: Moment | null;
  handleDateChange: (date: Moment | null) => void;
}

const CustomDateRangePicker: React.FC<IDateRangePickerProps> = ({
  selectedDate,
  handleDateChange,
}: IDateRangePickerProps) => {
  const classes = useStyles({ selectedDate, handleDateChange });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          label=""
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
            className: classes.iconButton,
            size: "small",
          }}
          keyboardIcon={<img src={selectedDate ? dateIconWhite : dateIcon} alt="date" height={16} />}
          TextFieldComponent={(props) => (
            <TextField
              inputProps={{
                readOnly: true,
              }}
              {...props}
              className={classes.textfield}
              variant="outlined"
              placeholder="Select Date"
            />
          )}
          autoOk
          PopoverProps={{
            classes: {
              paper: classes.popup,
            },
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default CustomDateRangePicker;
