Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.title = "Your prepaid accounting module";
exports.subTitle = "Amortize is THE stand alone Prepaid Module for ALL accounting departments.";
exports.loginBtn = "Login";
exports.subscribeBtn = "Subscribe now";
exports.premiumLabel = "Premium";
exports.priceValue = "$100";
exports.monthlyLabel = "monthly";
exports.subscriptionLabel = "Our monthly subscriptions";
exports.subscriptionDetailsLabel =
  "Amortize provides a cost efficient solution to accounting departments wanting to simplify their month end process. New ERP implementations can cost an accounting department too much time and money. Amortize allows you to be up and running in minutes! Summary reconciliations and monthly Journal Entries pull from your detail with accuracy and efficiency so you have Data you can rely on!";
exports.premiumPlansBenefits = [
  "Upload chart of accounts and vendors",
  "Identify your prepaid accounts",
  "Work and generate your prepaid details and let Amortize do the rest",
  "Save time and resources by auto generating a monthly journal entry",
  "Roll forward summary reconciliations and gain quick insights to monthly activities",
  "Feel confident in your data and be in control of your Prepaid Module!",
];
exports.getVideoAPIEndPoint = "bx_block_landingpage2/landing_video";
// Customizable Area End