import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

export interface S {}

export interface SS {}

export default class PaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {};
  }

  componentDidMount = async () => {
    const userDetails = await getStorageData("userDetails", true);
    if (!userDetails?.email) {
      this.handlePageRedirection("Home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  payWithStripe = async () => {
    await setStorageData("isFirstLoad", "true");
    this.handlePageRedirection("Details");
  };

  handlePageRedirection = (path: string) => {
    const pageRedirectionMsg = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    pageRedirectionMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      path
    );
    pageRedirectionMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(pageRedirectionMsg);
  };
}
