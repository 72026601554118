import React from "react";
import { Box } from "@material-ui/core";
import NavigationSidebar from "../../../blocks/navigationmenu/src/NavigationSidebar.web";
import DetailsController, { Props } from "./DetailsController.web";
import DetailsHomePage from "./DetailsHomePage.web";
import CustomMainPageWrapper from "../../../components/src/CustomMainPageWrapper.web";
import AccountIdentifiersStepper from "./AccountIdentifiersStepper.web";
import UploadDetailedInformation from "./UploadDetailedInformation.web";
import DetailsTableView from "./DetailsTableView.web";
import InconsistentDetailsView from "./InconsistentDetailsView.web";

export default class Details extends DetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      sidebarNavWidth,
      accountsData,
      isOpenDetailedUploadScreen,
      isOpenDetailTableView,
      loader,
      detailedSheetData,
      isOpenInconsistencyPage,
      missingIdentifierIds,
      missingVendorIds,
      isOpenDetailedUploadView,
      missingExpenseAccountIds
    } = this.state;
    return (
      <Box
        display={"flex"}
        sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
      >
        <Box>
          <NavigationSidebar
            navigation={this.props.navigation}
            onResize={this.onSidebarNavWidthChange}
            enableAllTabs={detailedSheetData.length > 0}
          />
        </Box>
        <CustomMainPageWrapper width={sidebarNavWidth} loader={loader}>
          {!accountsData.length &&
            !isOpenDetailedUploadScreen &&
            !isOpenDetailTableView &&
            !isOpenInconsistencyPage && (
              <DetailsHomePage
                setAccountsData={this.setAccountsData}
                tableWidth={sidebarNavWidth + 96}
                moveToDetailedUploadScreen={this.redirectToDetailedUploadScreen}
              />
            )}
          {accountsData.length > 0 &&
            !isOpenDetailedUploadScreen &&
            !isOpenDetailTableView &&
            !isOpenInconsistencyPage && (
              <AccountIdentifiersStepper
                accountsData={accountsData}
                tableWidth={sidebarNavWidth + 96}
                moveToDetailedUploadScreen={this.redirectToDetailedUploadScreen}
                backToFileUpload={this.backToInitialFileUpload}
              />
            )}
          {isOpenDetailedUploadScreen &&
            !isOpenDetailTableView &&
            !isOpenInconsistencyPage && (
              <UploadDetailedInformation
                tableWidth={sidebarNavWidth + 96}
                startFromScratch={this.emptyDetailsTable}
                uploadDetailedData={this.getMonthList}
                toggleLoader={this.toggleLoader}
                showInconsistencyPage={this.openInconsistencyPage}
                isOpenUploadPage={isOpenDetailedUploadView}
              />
            )}
          {isOpenDetailTableView && !isOpenInconsistencyPage && (
            <DetailsTableView
              tableWidth={sidebarNavWidth + 64}
              detailedSheetData={detailedSheetData}
              toggleLoader={this.toggleLoader}
              backToDetailedUpload={this.redirectToDetailedUploadScreen}
            />
          )}
          {isOpenInconsistencyPage && (
            <InconsistentDetailsView
              vendorIds={missingVendorIds}
              identifierIds={missingIdentifierIds}
              expenseAccountIds={missingExpenseAccountIds}
              tableWidth={sidebarNavWidth + 64}
              moveToDetailsScreen={this.getMonthList}
              toggleLoader={this.toggleLoader}
              backToDetailsUpload={this.backToDetailsUpload}
            />
          )}
        </CustomMainPageWrapper>
      </Box>
    );
  }
}
