Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.signInLabel = "Sign in";
exports.emailPlaceholder = "Your email";
exports.passwordPlaceholder = "Your password";
exports.forgotPasswordLabel = "Forgot your password?";
exports.logInLabel = "Log In";
exports.signUpLabel = "Sign up";
exports.dontHaveAnAccountLabel = "Don't have an account?";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.emailRequired = "Email is required";
exports.passwordRequired = "Password is required";
exports.emailInvalid = "Invalid Email";
exports.passwordInvalid =
  "Password must be 8 characters or longer and include at least one uppercase letter, one lowercase letter, and one number.";
exports.loginEndPoint = "bx_block_login/logins";
exports.somethingWentWrong = "Something went wrong!";
exports.getMonthListApiEndPoint = "bx_block_bulk_uploading/months_to_lock";
// Customizable Area End